export const instagram = (
  <svg viewBox="0 0 20 20" fill="none">
    <path
      d="M12.5 10C12.5 10.4945 12.3534 10.9778 12.0787 11.3889C11.804 11.8 11.4135 12.1205 10.9567 12.3097C10.4999 12.4989 9.99723 12.5484 9.51227 12.452C9.02732 12.3555 8.58186 12.1174 8.23223 11.7678C7.8826 11.4181 7.6445 10.9727 7.54804 10.4877C7.45157 10.0028 7.50108 9.50011 7.6903 9.04329C7.87952 8.58648 8.19995 8.19603 8.61107 7.92133C9.0222 7.64662 9.50555 7.5 10 7.5C10.6628 7.50076 11.2983 7.76439 11.7669 8.23307C12.2356 8.70175 12.4992 9.33719 12.5 10ZM17.8125 6.5625V13.4375C17.8112 14.5974 17.3498 15.7095 16.5296 16.5296C15.7095 17.3498 14.5974 17.8112 13.4375 17.8125H6.5625C5.40258 17.8112 4.29055 17.3498 3.47036 16.5296C2.65017 15.7095 2.18881 14.5974 2.1875 13.4375V6.5625C2.18881 5.40258 2.65017 4.29055 3.47036 3.47036C4.29055 2.65017 5.40258 2.18881 6.5625 2.1875H13.4375C14.5974 2.18881 15.7095 2.65017 16.5296 3.47036C17.3498 4.29055 17.8112 5.40258 17.8125 6.5625ZM13.75 10C13.75 9.25832 13.5301 8.5333 13.118 7.91661C12.706 7.29993 12.1203 6.81928 11.4351 6.53545C10.7498 6.25162 9.99584 6.17736 9.26841 6.32206C8.54098 6.46675 7.8728 6.8239 7.34835 7.34835C6.8239 7.8728 6.46675 8.54098 6.32206 9.26841C6.17736 9.99584 6.25162 10.7498 6.53545 11.4351C6.81928 12.1203 7.29993 12.706 7.91661 13.118C8.5333 13.5301 9.25832 13.75 10 13.75C10.9942 13.7489 11.9474 13.3534 12.6504 12.6504C13.3534 11.9474 13.7489 10.9942 13.75 10ZM15 5.9375C15 5.75208 14.945 5.57082 14.842 5.41665C14.739 5.26248 14.5926 5.14232 14.4213 5.07136C14.25 5.00041 14.0615 4.98184 13.8796 5.01801C13.6977 5.05419 13.5307 5.14348 13.3996 5.27459C13.2685 5.4057 13.1792 5.57275 13.143 5.7546C13.1068 5.93646 13.1254 6.12496 13.1964 6.29627C13.2673 6.46757 13.3875 6.61399 13.5417 6.717C13.6958 6.82002 13.8771 6.875 14.0625 6.875C14.3111 6.875 14.5496 6.77623 14.7254 6.60041C14.9012 6.4246 15 6.18614 15 5.9375Z"
      fill="currentColor"
    />
  </svg>
);
export const facebook = (
  <svg viewBox="0 0 20 20" fill="none">
    <path
      d="M13.7507 5H11.2507C10.7904 5 10.4173 5.3731 10.4173 5.83333V8.33333H13.7507C13.8454 8.33123 13.9353 8.37533 13.9917 8.45158C14.048 8.52783 14.0638 8.62667 14.034 8.71667L13.4173 10.55C13.3605 10.7182 13.2032 10.832 13.0257 10.8333H10.4173V17.0833C10.4173 17.3134 10.2307 17.5 10.0007 17.5H7.91732C7.6872 17.5 7.50065 17.3134 7.50065 17.0833V10.8333H6.25065C6.02053 10.8333 5.83398 10.6467 5.83398 10.4167V8.75C5.83398 8.51992 6.02053 8.33333 6.25065 8.33333H7.50065V5.83333C7.50065 3.99238 8.99307 2.5 10.834 2.5H13.7507C13.9807 2.5 14.1673 2.68655 14.1673 2.91667V4.58333C14.1673 4.81345 13.9807 5 13.7507 5Z"
      fill="currentColor"
    />
  </svg>
);
export const twitter = (
  <svg viewBox="0 0 20 20" fill="none">
    <path
      d="M17.478 5.59783C17.091 6.1141 16.6232 6.56444 16.0926 6.93147C16.0926 7.06633 16.0926 7.20118 16.0926 7.34354C16.0969 9.79258 15.1186 12.1409 13.3772 13.8621C11.6357 15.5833 9.27671 16.5333 6.82909 16.4991C5.41404 16.5038 4.01711 16.1808 2.74773 15.5551C2.67928 15.5252 2.63514 15.4575 2.6354 15.3828V15.3003C2.6354 15.1928 2.72257 15.1056 2.83011 15.1056C4.22106 15.0597 5.5624 14.5774 6.66433 13.727C5.40532 13.7016 4.27254 12.9558 3.75121 11.8089C3.72489 11.7463 3.73308 11.6744 3.77286 11.6193C3.81262 11.5643 3.87821 11.5339 3.94592 11.5393C4.32856 11.5777 4.71503 11.5421 5.08421 11.4343C3.69437 11.1458 2.65006 9.992 2.5006 8.57975C2.49529 8.512 2.52565 8.44642 2.58071 8.40658C2.63577 8.36684 2.70758 8.35858 2.7702 8.385C3.14316 8.54958 3.54579 8.63625 3.95341 8.63967C2.73558 7.84038 2.20956 6.32004 2.67284 4.93852C2.72066 4.80428 2.83556 4.7051 2.9753 4.67745C3.11503 4.64979 3.25901 4.69774 3.35431 4.80366C4.99771 6.55271 7.25575 7.59497 9.6523 7.71066C9.59096 7.46571 9.5608 7.21397 9.56246 6.96143C9.58488 5.63727 10.4042 4.45768 11.6369 3.97489C12.8695 3.49212 14.2715 3.80169 15.1865 4.7587C15.8102 4.63988 16.4132 4.43038 16.9763 4.13684C17.0175 4.11109 17.0699 4.11109 17.1111 4.13684C17.1369 4.17811 17.1369 4.23043 17.1111 4.2717C16.8384 4.89627 16.3776 5.4201 15.7931 5.77016C16.305 5.7108 16.8078 5.59006 17.2909 5.41053C17.3315 5.38285 17.385 5.38285 17.4256 5.41053C17.4597 5.42612 17.4852 5.45593 17.4953 5.49202C17.5054 5.52811 17.499 5.56682 17.478 5.59783Z"
      fill="currentColor"
    />
  </svg>
);

export const linkedin = (
  <svg viewBox="0 0 24 24" fill="none">
    <path
      d="M19.8184 13.6833V19.1995C19.8184 19.3349 19.7023 19.4511 19.5668 19.4511H16.7216C16.5861 19.4511 16.47 19.3349 16.47 19.1995V14.0704C16.47 12.7156 15.9861 11.7866 14.7668 11.7866C13.8377 11.7866 13.2958 12.4059 13.0442 13.0059C12.9474 13.2188 12.9281 13.5285 12.9281 13.8188V19.1995C12.9281 19.3349 12.8119 19.4511 12.6764 19.4511H9.85064C9.71516 19.4511 9.59903 19.3349 9.59903 19.1995C9.59903 17.8253 9.63774 11.1866 9.59903 9.63817C9.59903 9.50268 9.71516 9.38656 9.85064 9.38656H12.6958C12.8313 9.38656 12.9474 9.50268 12.9474 9.63817V10.8188C12.9474 10.8382 12.9281 10.8382 12.9281 10.8575H12.9474V10.8188C13.3926 10.1414 14.1861 9.1543 15.9668 9.1543C18.1732 9.1543 19.8184 10.6059 19.8184 13.6833ZM4.64419 19.4704H7.48935C7.62484 19.4704 7.74097 19.3543 7.74097 19.2188V9.63817C7.74097 9.50268 7.62484 9.38656 7.48935 9.38656H4.64419C4.50871 9.38656 4.39258 9.50268 4.39258 9.63817V19.2188C4.41193 19.3543 4.50871 19.4704 4.64419 19.4704Z"
      fill="currentColor"
    />
    <path
      d="M5.97969 8.1293C6.9738 8.1293 7.77969 7.32341 7.77969 6.3293C7.77969 5.33518 6.9738 4.5293 5.97969 4.5293C4.98557 4.5293 4.17969 5.33518 4.17969 6.3293C4.17969 7.32341 4.98557 8.1293 5.97969 8.1293Z"
      fill="currentColor"
    />
  </svg>
);
export const twitterX = (
  <svg viewBox="0 0 17 17" fill="none">
    <path
      d="M12.9315 1.26123H15.3339L10.0859 7.27463L16.2604 15.4588H11.4265L7.63759 10.4962L3.30713 15.4588H0.902446L6.51525 9.02471L0.594727 1.26235H5.55181L8.97141 5.79757L12.9315 1.26123ZM12.0867 14.0176H13.4182L4.82447 2.6275H3.39665L12.0867 14.0176Z"
      fill="currentColor"
    />
  </svg>
);
export const medium = (
  <svg viewBox="0 0 17 17" fill="none">
    <path
      d="M13.5424 4.80791L14.4802 3.90682V3.70947H11.2324L8.91769 9.49845L6.28481 3.70947H2.87935V3.90682L3.97473 5.23168C4.08182 5.32959 4.13689 5.4734 4.12313 5.6172V10.8233C4.15678 11.0115 4.09712 11.2027 3.96555 11.3389L2.73096 12.8427V13.037H6.22821V12.8397L4.99514 11.3404C4.92908 11.2733 4.87938 11.1919 4.84993 11.1025C4.82047 11.0131 4.81206 10.918 4.82533 10.8248V6.32094L7.89575 13.0401H8.25221L10.8927 6.32094V11.6739C10.8927 11.8146 10.8927 11.8437 10.8009 11.937L9.85091 12.8595V13.0569H14.4588V12.8595L13.5424 11.9585C13.4629 11.8973 13.4216 11.7948 13.4384 11.6953V5.07104C13.4305 5.02188 13.436 4.97149 13.4543 4.92519C13.4726 4.87888 13.503 4.83836 13.5424 4.80791Z"
      fill="currentColor"
    />
  </svg>
);
export const telegram = (
  <svg viewBox="0 0 17 17" fill="none">
    <path
      d="M14.8158 1.91695L0.936741 7.29672C0.378221 7.54725 0.189315 8.04895 0.801747 8.32124L4.36233 9.45862L12.9714 4.11055C13.4414 3.77481 13.9227 3.86434 13.5086 4.23368L6.11457 10.9631L5.88231 13.8109C6.09743 14.2506 6.49134 14.2527 6.7426 14.0341L8.78827 12.0885L12.2918 14.7255C13.1055 15.2098 13.5483 14.8973 13.7234 14.0098L16.0214 3.07221C16.26 1.97974 15.8531 1.49839 14.8158 1.91695Z"
      fill="currentColor"
    />
  </svg>
);
export const discord = (
  <svg viewBox="0 0 16 17" fill="none">
    <path
      d="M13.2945 2.92123C12.3409 2.48467 11.3343 2.17495 10.3002 2C10.1587 2.25295 10.0307 2.51321 9.91666 2.77968C8.81519 2.6137 7.69507 2.6137 6.5936 2.77968C6.47951 2.51324 6.35148 2.25299 6.21003 2C5.17532 2.17643 4.16798 2.48688 3.21346 2.92352C1.31848 5.72717 0.804784 8.46119 1.06163 11.1564C2.17138 11.9763 3.4135 12.5999 4.73401 13C5.03135 12.6001 5.29445 12.1758 5.52054 11.7317C5.09112 11.5714 4.67666 11.3735 4.28195 11.1404C4.38583 11.0651 4.48743 10.9874 4.58561 10.9121C5.73412 11.4522 6.98766 11.7323 8.25684 11.7323C9.52602 11.7323 10.7796 11.4522 11.9281 10.9121C12.0274 10.9932 12.129 11.0708 12.2317 11.1404C11.8363 11.3739 11.421 11.5721 10.9909 11.7329C11.2167 12.1768 11.4798 12.6007 11.7774 13C13.099 12.6015 14.3421 11.9782 15.452 11.1575C15.7534 8.03196 14.9372 5.32306 13.2945 2.92123ZM5.84588 9.49886C5.13013 9.49886 4.5388 8.84931 4.5388 8.05023C4.5388 7.25114 5.10958 6.59589 5.8436 6.59589C6.57761 6.59589 7.16437 7.25114 7.15182 8.05023C7.13926 8.84931 6.57533 9.49886 5.84588 9.49886ZM10.6678 9.49886C9.9509 9.49886 9.36186 8.84931 9.36186 8.05023C9.36186 7.25114 9.93264 6.59589 10.6678 6.59589C11.403 6.59589 11.9851 7.25114 11.9726 8.05023C11.96 8.84931 11.3972 9.49886 10.6678 9.49886Z"
      fill="currentColor"
    />
  </svg>
);
export const tikTok = (
  <svg fill="currentColor" viewBox="0 0 512 512">
    <path d="M412.19,118.66a109.27,109.27,0,0,1-9.45-5.5,132.87,132.87,0,0,1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14,23.9,350,16,350.13,16H267.69V334.78c0,4.28,0,8.51-.18,12.69,0,.52-.05,1-.08,1.56,0,.23,0,.47-.05.71,0,.06,0,.12,0,.18a70,70,0,0,1-35.22,55.56,68.8,68.8,0,0,1-34.11,9c-38.41,0-69.54-31.32-69.54-70s31.13-70,69.54-70a68.9,68.9,0,0,1,21.41,3.39l.1-83.94a153.14,153.14,0,0,0-118,34.52,161.79,161.79,0,0,0-35.3,43.53c-3.48,6-16.61,30.11-18.2,69.24-1,22.21,5.67,45.22,8.85,54.73v.2c2,5.6,9.75,24.71,22.38,40.82A167.53,167.53,0,0,0,115,470.66v-.2l.2.2C155.11,497.78,199.36,496,199.36,496c7.66-.31,33.32,0,62.46-13.81,32.32-15.31,50.72-38.12,50.72-38.12a158.46,158.46,0,0,0,27.64-45.93c7.46-19.61,9.95-43.13,9.95-52.53V176.49c1,.6,14.32,9.41,14.32,9.41s19.19,12.3,49.13,20.31c21.48,5.7,50.42,6.9,50.42,6.9V131.27C453.86,132.37,433.27,129.17,412.19,118.66Z" />
  </svg>
);
