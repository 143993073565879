import React from "react";

export default function UpHeader() {
  return (
    <div className="upHeader">
      <div className="auto__container">
        <div className="upHeader__inner">
          <a href="mailto:hello@betto.ai">Contact Us</a>
        </div>
      </div>
    </div>
  );
}
