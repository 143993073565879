import React from "react";

export default function Mission() {
  return (
    <section className="mission">
      <div className="mission__bg">
        <div className="shape">
          <img src="images/blur-2.svg" alt="" />
        </div>
        <div className="shape right">
          <img className="desc" src="images/blur-1.svg" alt="" />
          <img className="mob" src="images/blur-2.svg" alt="" />
        </div>
      </div>
      <div className="auto__container">
        <div className="mission__inner">
          <div className="mission__inner-grid">
            <div className="mission__inner-col">
              <div className="missionItem sm tall">
                <h2>67.4%</h2>
                <p>Strike Rate</p>
              </div>
              <div
                className="missionItem"
                style={{
                  background:
                    "linear-gradient(128.16deg, #fef392 2.22%, #9ffee9 93.66%)",
                }}
              >
                <h2>25+</h2>
                <p>Sports Supported</p>
              </div>
              <div
                className="missionItem sm short"
                style={{
                  background:
                    "linear-gradient(209.69deg, #fded92 9.39%, #ec4ba8 188.81%)",
                }}
              >
                <h2>33.9%</h2>
                <p>AVG ROI</p>
              </div>
            </div>
            <div className="mission__inner-col">
              <div
                className="missionItem"
                style={{
                  background:
                    "linear-gradient(48.69deg, #24d9ae 0.24%, #fbf392 100%)",
                }}
              >
                <h4>100,000,000,000</h4>
                <p>Data Points</p>
              </div>
              <div
                className="missionItem ex"
                style={{
                  background:
                    "linear-gradient(167.4deg, #89ffe4 9.13%, #23d7ad 93.93%)",
                }}
              >
                <h4>4.4 Seconds</h4>
                <p>Average Response</p>
              </div>
            </div>
          </div>
          <div className="mission__inner-content">
            <h2>Our Mission</h2>
            <p>
              Engaging with the best sports betting apps, our goal aligns
              perfectly with users seeking the best bets today. Leveraging
              artificial intelligence in sports picks ensures precision. Embrace
              methods on how to use AI for maximized returns. Our platform
              offers insights on the best parlay bets today, blending expert
              betting tips with innovative AI sports betting strategies.
            </p>
            <p>
              Our commitment extends beyond traditional methods, incorporating
              AI into every aspect of sports betting. This integration allows us
              to provide unmatched accuracy in our sports picks. Delve into our
              resources for learning how to use AI to enhance your betting
              strategies. Whether you seek the best bets today or the best
              parlay bets today, our platform is your ultimate guide.
            </p>
            <p>
              Our journey in the sports betting landscape is driven by a
              commitment to innovation and excellence. By incorporating
              cutting-edge artificial intelligence, we enhance our ability to
              deliver precise and actionable betting tips. Our platform serves
              both beginners and seasoned bettors, providing valuable insights
              and tools to help everyone make informed decisions. Whether you’re
              exploring simple bets or aiming for complex parlays, we're here to
              support your passion for the game and betting success.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
