import React, { useEffect, useState } from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
  RadialLinearScale,
  ArcElement,
  registerables as registerablesJS,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Pie } from "react-chartjs-2";
ChartJS.register(...registerablesJS);
ChartJS.register(
  CategoryScale,
  PointElement,
  Title,
  Tooltip,
  RadialLinearScale,
  ArcElement,
  Legend,
  ChartDataLabels
);

export default function PieChart({ colors, tokenData, labels }) {
  const [fontSize, setFontSize] = useState(18);
  useEffect(() => {
    if (window.innerWidth < 1180 && window.innerWidth > 930) {
      setFontSize(14);
    } else if (window.innerWidth <= 930 && window.innerWidth > 440) {
      setFontSize(18);
    } else if (window.innerWidth <= 440) {
      setFontSize(14);
    } else {
      setFontSize(18);
    }
    window.addEventListener("resize", () => {
      if (window.innerWidth < 1180 && window.innerWidth > 930) {
        setFontSize(14);
      } else if (window.innerWidth <= 930 && window.innerWidth > 440) {
        setFontSize(18);
      } else if (window.innerWidth <= 440) {
        setFontSize(14);
      } else {
        setFontSize(18);
      }
    });
  }, []);

  function createRadialGradient3(context, c1, c3, d1, d3, cang, name) {
    let gradient, width, height;
    const chartArea = context.chart.chartArea;
    if (!chartArea) {
      return;
    }
    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;
    if (!gradient) {
      width = chartWidth;
      height = chartHeight;
      const ctx = context.chart.ctx;
      const angle = ((180 - cang) / 180) * Math.PI;
      const length =
        Math.abs(width * Math.sin(angle)) + Math.abs(height * Math.cos(angle));
      const halfx = (Math.sin(angle) * length) / 2.0;
      const halfy = (Math.cos(angle) * length) / 2.0;
      const cx = width / 2.0;
      const cy = height / 2.0;
      const x1 = cx - halfx;
      const y1 = cy - halfy;
      const x2 = cx + halfx;
      const y2 = cy + halfy;
      gradient = ctx.createLinearGradient(x1, y1, x2, y2);
      gradient.addColorStop(d1, c1);
      gradient.addColorStop(d3, c3);
    }

    return gradient;
  }

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: true,
        formatter: (value, context) => {
          console.log(context.dataIndex);
          return value + "%";
        },
        color: "#0A1B27",
        align: "start",
        anchor: "end",
        offset: 8,
        clamp: true,
        font: {
          family: "Inter",
          size: fontSize,
          lineHeight: 1,
          weight: "bold",
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    elements: {
      arc: {
        borderColor: "rgba(0,0,0,0)",
        borderAlign: "inner",
        backgroundColor: function (context) {
          let c = colors[context.dataIndex];
          if (!c) {
            return;
          }
          //   if (context.active) {
          //     c = helpers.getHoverColor(c);
          //   }

          return createRadialGradient3(
            context,
            c.start,
            c.end,
            c.d1,
            c.d3,
            c.ang,
            c.name
          );
        },
      },
    },
    scales: {
      // r: {
      //   angleLines: {
      //     display: true,
      //   },
      //   ticks: {
      //     display: false,
      //   },
      //   grid: {
      //     display: false,
      //   },
      // },
      y: {
        ticks: {
          display: false,
        },
        grid: {
          display: false,
          drawTicks: false,
        },
      },
      x: {
        ticks: {
          display: false,
        },
        grid: {
          display: false,
          drawTicks: false,
        },
      },
    },
  };

  const data = {
    labels: ["", "", "", ""],
    datasets: [
      {
        data: tokenData,
      },
    ],
  };
  return <Pie options={options} data={data} />;
}
