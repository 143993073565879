import React from "react";

export default function Choice() {
  return (
    <section className="choice">
      <div className="choice__bg">
        <div className="shape">
          <img src="images/blur-2.svg" alt="" />
        </div>
      </div>
      <div className="auto__container">
        <div className="choice__inner">
          <div className="choice__inner-title">
            <h2>Why Choose Us</h2>
          </div>
          <div className="choice__inner-row">
            <div className="choiceItem">
              <h3>100%</h3>
              <h4>Revenue Share</h4>
              <p>
                At BETTO.ai, we value our partners. Our revenue share program is
                designed to maximize your earnings through a transparent and
                lucrative model. Earn a substantial share from the revenue
                generated by users you refer to our platform. With real-time
                earnings tracking and regular payouts, partnering with BETTO.ai
                means joining a network that supports your growth and rewards
                your efforts directly.
              </p>
            </div>
            <div className="choiceItem">
              <h3>$11B</h3>
              <h4>Market Size</h4>
              <p>
                BETTO.ai operates within the vast $11 billion global sports
                betting market. This immense market offers unparalleled
                opportunities for growth and profitability. By choosing
                BETTO.ai, you're tapping into an industry rich with potential,
                backed by our cutting-edge AI technology and expert insights.
                Join us to capitalize on the expansive reach and dynamic
                possibilities of this thriving market.
              </p>
            </div>
            <div className="choiceItem">
              <h3>100%</h3>
              <h4>APY from Staking</h4>
              <p>
                BETTO.ai offers up to 100% Annual Percentage Yield (APY) from
                staking, providing one of the most lucrative returns in the
                industry. Our staking program allows you to earn significant
                returns on your investments by simply holding your tokens in our
                platform. This not only enhances your earning potential but also
                supports the stability and security of our network. Watch your
                assets grow exponentially in a secure environment.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
