import React from "react";
import PolarChart from "./PolarChart";
import PieChart from "./PieChart";

export default function Token() {
  const colors = [
    {
      name: "1",
      start: "#F49CBA",
      end: "#EC4CA8",
      d1: "0.375",
      d3: "0.7894",
      ang: 324.54,
    },
    {
      name: "2",
      start: "#26D9AF",
      end: "#94F4B5",
      d1: "0.3787",
      d3: "0.6784",
      ang: 141.24,
    },
    {
      name: "3",
      start: "#FFD3A0",
      end: "#FEA564",
      d1: "0.0642",
      d3: "0.8693",
      ang: 160.52,
    },
    {
      name: "4",
      start: "#FEF2A2",
      end: "#E7C787",
      d1: "0.1626",
      d3: "1",
      ang: 217.44,
    },
  ];
  const colors2 = [
    {
      name: "1",
      start: "#F49CBA",
      end: "#EC4CA8",
      d1: "0.375",
      d3: "0.7894",
      ang: 324.54,
    },
    {
      name: "2",
      start: "#26D9AF",
      end: "#94F4B5",
      d1: "0.3787",
      d3: "0.6784",
      ang: 141.24,
    },
    {
      name: "3",
      start: "#FFD3A0",
      end: "#FEA564",
      d1: "0.0642",
      d3: "0.8693",
      ang: 160.52,
    },
    {
      name: "4",
      start: "#FEF2A2",
      end: "#E7C787",
      d1: "0.1626",
      d3: "1",
      ang: 217.44,
    },
    {
      name: "5",
      start: "#50E0FF",
      end: "#8EEBFF",
      d1: "0.3495",
      d3: "0.774",
      ang: 153.41,
    },
    {
      name: "6",
      start: "#AB8BEF",
      end: "#D2BDFF",
      d1: "0.3495",
      d3: "0.774",
      ang: 153.41,
    },
    {
      name: "7",
      start: "#FF9797",
      end: "#EF6666",
      d1: "0.3495",
      d3: "0.774",
      ang: 153.41,
    },
    {
      name: "8",
      start: "#80B3FF",
      end: "#488AEE",
      d1: "0",
      d3: "1",
      ang: 180,
    },
  ];
  return (
    <section className="token">
      <div className="token__bg">
        <div className="shape">
          <img src="images/blur-2.svg" alt="" />
        </div>
        <div className="shape right">
          <img src="images/blur-2.svg" alt="" />
        </div>
      </div>
      <div className="auto__container">
        <div className="token__inner">
          <div className="token__inner-title">
            <h2>Token allocation</h2>
          </div>
          <div className="token__inner-row">
            <div className="tokenItem">
              <div className="tokenItem__chart">
                <span></span>
                <PieChart
                  colors={colors}
                  tokenData={[33, 33, 22, 12]}
                  labels={["", "", "", ""]}
                />
                {/* <img src="./images/chart.svg" alt="" /> */}
              </div>
              <div className="tokenItem__content">
                <h4>Use of Funds</h4>
                <ul>
                  <li>
                    <span
                      style={{
                        background:
                          "linear-gradient(324.54deg, #F49CBA 37.5%, #EC4CA8 78.94%)",
                      }}
                    ></span>
                    <p>Marketing + Partnerships 33%</p>
                  </li>
                  <li>
                    <span
                      style={{
                        background:
                          "linear-gradient(141.24deg, #26D9AF 37.87%, #94F4B5 67.84%)",
                      }}
                    ></span>
                    <p>Locked Liquidity 33%</p>
                  </li>
                  <li>
                    <span
                      style={{
                        background:
                          "linear-gradient(160.52deg, #FFD3A0 6.42%, #FEA564 86.93%)",
                      }}
                    ></span>
                    <p>Platform Development 22%</p>
                  </li>
                  <li>
                    <span
                      style={{
                        background:
                          "linear-gradient(217.44deg, #FEF2A2 -16.26%, #E7C787 158.62%)",
                      }}
                    ></span>
                    <p>Team + Founders 11%</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="tokenItem">
              <div className="tokenItem__content">
                <h4>Token Allocation</h4>
                <ul>
                  <li>
                    <span
                      style={{
                        background:
                          "linear-gradient(324.54deg, #F49CBA 37.5%, #EC4CA8 78.94%)",
                      }}
                    ></span>
                    <p>Community Fund 5%</p>
                  </li>
                  <li>
                    <span
                      style={{
                        background:
                          "linear-gradient(141.24deg, #26D9AF 37.87%, #94F4B5 67.84%)",
                      }}
                    ></span>
                    <p>Platform Rewards 10%</p>
                  </li>
                  <li>
                    <span
                      style={{
                        background:
                          "linear-gradient(160.52deg, #FFD3A0 6.42%, #FEA564 86.93%)",
                      }}
                    ></span>
                    <p>Initial Liquidity 10%</p>
                  </li>
                  <li>
                    <span
                      style={{
                        background:
                          "linear-gradient(217.44deg, #FEF2A2 -16.26%, #E7C787 158.62%)",
                      }}
                    ></span>
                    <p>Private Sale 10%</p>
                  </li>
                  <li>
                    <span
                      style={{
                        background:
                          "linear-gradient(153.41deg, #50E0FF 34.95%, #8EEBFF 77.4%)",
                      }}
                    ></span>
                    <p>Public Sale 15%</p>
                  </li>
                  <li>
                    <span
                      style={{
                        background:
                          "linear-gradient(153.41deg, #AB8BEF 34.95%, #D2BDFF 77.4%)",
                      }}
                    ></span>
                    <p>Team and Founders 15%</p>
                  </li>
                  <li>
                    <span
                      style={{
                        background:
                          "linear-gradient(153.41deg, #FF9797 34.95%, #EF6666 77.4%)",
                      }}
                    ></span>
                    <p>Staking Rewards 15%</p>
                  </li>
                  <li>
                    <span
                      style={{
                        background:
                          "linear-gradient(180deg, #80B3FF 0%, #488AEE 100%)",
                      }}
                    ></span>
                    <p>Marketing and Partnerships 20%</p>
                  </li>
                </ul>
              </div>
              <div className="tokenItem__chart">
                <span></span>
                <PieChart
                  colors={colors2}
                  tokenData={[5, 10, 10, 10, 15, 15, 15, 20]}
                  labels={["", "", "", "", "", "", "", ""]}
                />
                {/* <img src="./images/chart.svg" alt="" /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
